import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import BlogCategories from '../../../components/BlogCategories';

const PropertyBoundariesRights = () => (
  <Layout
    title="Articles about surveying and RPR (Real Property Report) requirements relating to municipal bylaws permits and related issues."
    description="Want to learn more about the bylaws in Calgary, Red Deer and Edmonton and how they effect an RPR / Real Property Reports? This a in depth blog style resource to help you understand the details of the many permit and by-law issues."
    keywords=""
  >
    <main>
      <BlogCategories />

      <h1>Category: Property Boundaries & Rights</h1>
      <ul>
        <li>
          <Link to="/articles/calgary-fence-bylaws-and-regulations">
            Calgary Fence Bylaws and Regulations
          </Link>
          <br />
          Building a fence in Calgary isn't quite as simple as you might imagine. While the physical
          task of erecting a fence isn't particularly challenging with professional help, if you
          live in the Calgary area, you will need to circumnavigate a little bureaucracy before
          heading out to buy your lumber. The City of Calgary fence bylaws dictate specific
          requirements that homeowners need to follow. Knowing these rules upfront can save you from
          a wide range of hassles later on.
        </li>
        <li>
          <Link to="/articles/certificate-of-title-alberta">Certificate of Title Alberta</Link>
          <br />A land title certificate in Alberta is an official document proving property
          ownership. It includes ownership details, legal descriptions, and registered encumbrances
          like liens or mortgages. Obtainable online via SPIN2 or in person at a registry office, it
          ensures accurate property transactions, legal security, and dispute resolution for owners,
          buyers, and lenders.
        </li>
        <li>
          <Link to="/articles/overland-drainage-right-of-ways">
            Overland Drainage Right of Ways
          </Link>
          <br />A Right of Way (R.W.) is a legally registered agreement associated with a land
          title, defining specific usage rights and restrictions for a portion of land. An Overland
          Drainage Right of Way can limit property owners' ability to inhibit drainage or install
          structures within this designated area.
        </li>
        <li>
          <Link to="/articles/relaxation-and-encroachment-info-2022">
            Relaxation and Encroachment Information updated for 2022
          </Link>
          <br />
          Encroachment agreements, legal arrangements resolving disputes arising when one party's
          property improvements encroach onto another's, are crucial for addressing issues like
          fences, decks, retaining walls, or other property enhancements that may infringe upon
          municipal property or rights of way.
        </li>
      </ul>
    </main>
  </Layout>
);

export default PropertyBoundariesRights;
